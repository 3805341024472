<template>
  <div>
    <EditDialog
      width="35%"
      :isReturn="true"
      @closeFn="$emit('update:agreementShow', false)"
      ref="IframeDlg"
      :isShow.sync="agreementShow"
    >
      <div slot="title"><span style="color: #409eff">确定完毕</span></div>
      <div slot="content" class="content">
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="执行完毕时间" prop="executionCompletedDate">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.executionCompletedDate"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="executionCompletedRemark">
            <el-input
              v-model="form.executionCompletedRemark"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 88 }"
            ></el-input> </el-form-item
        ></el-form>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" @click="submitFinshFn">保存</el-button>
        <el-button type="info" @click="$emit('update:agreementShow', false)">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    agreementShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        executionCompletedDate: Date.now(),
        executionCompletedRemark: null,
      },
      rules: {
        executionCompletedDate: [
          { required: true, message: '请选择执行完毕时间', trigger: ['change', 'blur'] },
        ],
        executionCompletedRemark: [
          { required: true, message: '请输入备注', trigger: ['change', 'blur'] },
        ],
      },
    }
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    submitFinshFn() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('submitFinshFn', this.form)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  filters: {},
}
</script>

<style lang="scss" scoped></style>
